// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/header/Calendar.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/header/Calendar.tsx");
  import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

import React, { useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils/class-names';
const Calendar = ({
  highlightedDates
}) => {
  _s();
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const today = new Date();
  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };
  const changeMonth = offset => {
    setCurrentMonth(new Date(currentMonth.getFullYear(), currentMonth.getMonth() + offset));
  };
  const firstDayOfMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
  const prependDays = (firstDayOfMonth.getDay() + 6) % 7;
  const days = [];
  const totalDays = prependDays + getDaysInMonth(currentMonth.getFullYear(), currentMonth.getMonth());

  // Days from previous month
  for (let i = 0; i < prependDays; i++) {
    const day = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i - prependDays + 1);
    days.push(day);
  }

  // Days of current month
  for (let i = 1; i <= getDaysInMonth(currentMonth.getFullYear(), currentMonth.getMonth()); i++) {
    const day = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), i);
    days.push(day);
  }

  // Days from next month
  const nextMonthDays = 42 - totalDays;
  for (let i = 1; i <= nextMonthDays; i++) {
    const day = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, i);
    days.push(day);
  }
  const dayNames = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
  return <div className="calendar-container rounded-lg">
      <div className="flex justify-between items-center p-2 font-bold text-black">
        <button onClick={() => changeMonth(-1)} aria-label="Previous month">
          <ChevronLeftIcon className="w-5 h-5" />
        </button>
        <span>
          {currentMonth.toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric'
        })}
        </span>
        <button onClick={() => changeMonth(1)} aria-label="Next month">
          <ChevronRightIcon className="w-5 h-5" />
        </button>
      </div>
      <div className="grid grid-cols-7">
        {dayNames.map(dayName => <div key={dayName} className="p-2 font-bold text-black">
            {dayName}
          </div>)}
        {days.map((day, index) => {
        const isToday = day.toDateString() === today.toDateString();
        const isHighlightedDate = highlightedDates.some(highlightedDate => day.toDateString() === new Date(highlightedDate).toDateString());
        const isCurrentMonth = day.getMonth() === currentMonth.getMonth();
        const dayClass = classNames('p-2', isHighlightedDate ? 'text-white calendar-bg-green' : isToday ? 'text-white calendar-bg-grey' : !isCurrentMonth ? 'calendar-light-grey' : 'text-black');
        return <div key={index} className={`p-2 ${dayClass} flex justify-center items-center p-1 rounded-md`}>
              {day.getDate()}
            </div>;
      })}
      </div>
    </div>;
};
_s(Calendar, "N6MwIQ3MTTQfe+bC1boIoFejPG4=");
_c = Calendar;
export default Calendar;
var _c;
$RefreshReg$(_c, "Calendar");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;