// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/main.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/main.tsx");
  import.meta.hot.lastModified = "1742995935000";
}
// REMIX HMR END

import { Outlet, Scripts, ScrollRestoration } from '@remix-run/react';
import { Header } from './components/header/Header';
import { useEffect, useState } from 'react';
import { useActiveOrder } from '~/utils/use-active-order';
import Footer from './components/footer/Footer';
import { useOrders } from './hooks/useOrders';
import { ShoppingCart } from './components/cart/ShoppingCart';
export default function Main(props) {
  _s();
  const [open, setOpen] = useState(false);
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const {
    orders,
    statistics,
    refreshOrders,
    ordersLoading
  } = useOrders();
  const loaderData = props.loaderData;
  const {
    activeCustomer,
    collections
  } = loaderData;
  const {
    activeOrderFetcher,
    activeOrder,
    adjustOrderLine,
    removeItem,
    refresh
  } = useActiveOrder();
  useEffect(() => {
    // When the loader has run, this implies we should refresh the contents
    // of the activeOrder as the user may have signed in or out.
    refresh();
  }, [loaderData]);
  return <>
      {/*
       <Header
        onCartIconClick={() => setOpen(!open)}
        cartQuantity={activeOrder?.lines.length ?? 0}
       />
       */}
      <Header onCartIconClick={() => setOpenShoppingCart(!openShoppingCart)} cartQuantity={orders ? orders.length : 0} />
      <main className="">
        <Outlet context={{
        activeOrderFetcher,
        activeOrder,
        adjustOrderLine,
        removeItem
      }} />
      </main>
      <ShoppingCart customer={activeCustomer.activeCustomer} open={openShoppingCart} onClose={setOpenShoppingCart} activeOrder={activeOrder} adjustOrderLine={adjustOrderLine} removeItem={removeItem} orders={orders} refreshOrders={refreshOrders} statistics={statistics} loading={ordersLoading} />
      {/*
        <CartTray
        open={open}
        onClose={setOpen}
        activeOrder={activeOrder}
        adjustOrderLine={adjustOrderLine}
        removeItem={removeItem}
       />
        */}
      <ScrollRestoration />
      <Scripts />
      <Footer collections={collections}></Footer>
    </>;
}
_s(Main, "FpKTw35E1zrFAxENmIN5OfFGHHA=", false, function () {
  return [useOrders, useActiveOrder];
});
_c = Main;
var _c;
$RefreshReg$(_c, "Main");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;